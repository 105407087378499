<template>
    <div class="dialog ct" @click.self="dismissDialog">
        <div class="dialog-container">
            <div class="title-bar">
                <span class="title">測試結果</span>
                <span class="close-dialog" @click="dismissDialog"></span>
            </div>
            <div class="base-info">
                <span class="icon-gender man"></span>
                <div class="info-item">
                    <span class="age" v-text="`${month}個月`"></span>
                    <span class="gender" v-text="`性别: ${params.gender==1?'男':'女'}`"></span>
                </div>
                <ul class="attrs">
                    <li class="attr height">身高:<span class="attr-v" v-text="params.height"></span>cm</li>
                    <li class="attr head-c">頭圍:<span class="attr-v" v-text="params.head"></span>cm</li>
                    <li class="attr weight">體重:<span class="attr-v" v-text="params.weight"></span>kg</li>
                    <li class="attr chest-c">胸圍:<span class="attr-v" v-text="params.chest">66</span>cm</li>
                </ul>
            </div>
            <div class="hint-result-list" v-text="`${month}個月發育參考值`"></div>
            <div class="result-list">
                <div class="result-items-l">
                    <div class="result-item-big level2">
                        <span class="hint">身高(cm)</span>
                        <span class="value" v-text="testResult.heightTestLevel"></span>
                    </div>
                    <div class="result-item-big level1">
                        <span class="hint">體重(kg)</span>
                        <span class="value" v-text="testResult.weightTestLevel"></span>
                    </div>
                    <div class="result-item-big level3">
                        <span class="hint">頭圍(cm)</span>
                        <span class="value" v-text="testResult.headTestLevel"></span>
                    </div>
                    <div class="result-item-big level4">
                        <span class="hint">胸圍(cm)</span>
                        <span class="value" v-text="testResult.chestTestLevel"></span>
                    </div>
                </div>
                <div class="result-items-r">
                    <div class="result-items height">
                        <div :class="{'result-item': true, ['level'+i]: true, checked: testResult.heightTestLevel && v == testResult.heightTestLevel }" v-for="(v, i) in testResult.heightAllLevel" :key="i">
                            <span class="result-item-title" v-text="v"></span>
                            <span class="result-item-value" v-text="testResult.heightData[i]"></span>
                        </div>
                    </div>
                    <div class="result-items weight">
                        <div :class="{'result-item': true, ['level'+i]: true, 'checked': testResult.weightTestLevel && v == testResult.weightTestLevel }" v-for="(v, i) in testResult.weightAllLevel" :key="i">
                            <span class="result-item-title" v-text="v"></span>
                            <span class="result-item-value" v-text="testResult.weightData[i]"></span>
                        </div>
                    </div>
                    <div class="result-items head-c">
                        <div :class="{'result-item': true, ['level'+i]: true, 'checked': testResult.headTestLevel && v == testResult.headTestLevel }" v-for="(v, i) in testResult.headAllLevel" :key="i">
                            <span class="result-item-title" v-text="v"></span>
                            <span class="result-item-value" v-text="testResult.headData[i]"></span>
                        </div>
                    </div>
                    <div class="result-items chest-c">
                        <div :class="{'result-item': true, ['level'+i]: true, 'checked': testResult.chestTestLevel && v == testResult.chestTestLevel }" v-for="(v, i) in testResult.chestAllLevel" :key="i">
                            <span class="result-item-title" v-text="v"></span>
                            <span class="result-item-value" v-text="testResult.chestData[i]"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="v-result-list">
                <span class="hint">檢測結果:</span>
                <span class="value" v-text="resultText || '--'"></span>
            </div>
            <div class="btn-confirm" @click="dismissDialog">好的！我知道了</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        callback: Function,
        params: null,
        testResult: null,
    },
    data() {
        return {
            resultText: '',
            month: 0,
        }
    },
    mounted: function(){
        var birthday = this.params.birthday
        var now = Date.now()
        var time = new Date(birthday).getTime()

        this.month = ((now - time) / 1000 / 60 / 60 / 24 / 30).toFixed(1)

        

        try {
            this.testResult.headData = [
                this.testResult.headData[0],
                `${this.testResult.headData[1]}±${this.testResult.headData[2]}`,
                this.testResult.headData[3],
            ]
        } catch (err){
        }
        var list = []
        var v1 = this.testResult.heightTestLevel ? `身高: ${this.testResult.heightTestLevel}` : ''
        if(v1) {
            list.push(v1)
        }
        var v2 = this.testResult.weightTestLevel ? `體重: ${this.testResult.weightTestLevel}` : ''
        if(v2) {
            list.push(v2)
        }
        var v3 = this.testResult.headTestLevel ? `頭圍: ${this.testResult.headTestLevel}` : ''
        if(v3) {
            list.push(v3)
        }
        var v4 = this.testResult.chestTestLevel ? `胸圍: ${this.testResult.chestTestLevel}` : ''
        if(v4) {
            list.push(v4)
        }
        this.resultText = list.join(' / ')
    },
    methods: {
        dismissDialog: function() {
            this.callback && this.callback()
        },
    }
}
</script>

<style scoped>
    .dialog {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1000;
        background-color: rgba(4, 58, 61, 0.3);
        overflow: hidden;
    }

    .dialog > .dialog-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 97%;
        height: 680px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .dialog> .dialog-container .title-bar {
        display: inline-block;
        height: 46px;
        line-height: 46px;
        background-color: #2B99A6;
        padding-left: 30px;
        font-weight: bold;
        font-size: 20px;
        color: #fff;
    }

    .dialog > .dialog-container .title-bar .close-dialog {
        float: right;
        width: 46px;
        height: 46px;
        position: relative;
        background-color: #36C4D0;
        cursor: pointer;
    }

    .dialog > .dialog-container .title-bar .close-dialog::after {
        width: 20px;
        height: 20px;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url(../../../assets/imgs/pc/img_cha_white@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .dialog.ct .btn-confirm {
        background-color: #2B99A6;
        width: 228px;
        height: 44px;
        text-align: center;
        line-height: 44px;
        font-weight: bold;
        font-size: 18px;
        color: #fff;
        border-radius: 10px;
        margin: 78px auto 0px auto;
        cursor: pointer;
        user-select: none;
    }

    .dialog.ct .btn-confirm:active {
        opacity: .7;
    }

    .dialog.ct .base-info {
        width: 864px;
        height: 64px;
        border-radius: 8px;
        border: 1px solid #E3EFF2;
        display: flex;
        flex-direction: row;
        margin: 30px 36px;
    }

    .dialog.ct .base-info .icon-gender {
        display: inline-block;
        width: 44px;
        height: 44px;
        margin: 10px 14px 10px 23px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .dialog.ct .base-info .icon-gender.man {
        background-image: url(../../../assets/imgs/pc/children/img_boy@2x.png);
    }

    .dialog.ct .base-info .icon-gender.woman {
        background-image: url(../../../assets/imgs/pc/children/img_girl@2x.png);
    }

    .dialog.ct .base-info .info-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .dialog.ct .base-info .info-item > .age {
        color: #231F20;
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
    }

    .dialog.ct .base-info .info-item > .gender {
        color: #969696;
        font-weight: 400;
        font-size: 14px;
        line-height: 12px;
        margin-top: 10px;
    }

    .dialog.ct .base-info .attrs {
        display: grid;
        grid-template-columns: 169px 169px;
        grid-template-rows: 30px 30px;
        margin-left: 146px;
    }

    .dialog.ct .base-info .attrs > .attr {
        height: 30px;
        line-height: 30px;
        font-weight: 400;
        font-size: 16px;
        color: #231F20;
        position: relative;
        padding-left: 24px;
    }

    .dialog.ct .base-info .attrs > .attr::before {
        content: '';
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0px;
        top: 8px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .dialog.ct .base-info .attrs > .attr.height::before {
        background-image: url(../../../assets/imgs/pc/children/img_sheng@2x.png);
        width: 17px;
        height: 15px;
    }

    .dialog.ct .base-info .attrs > .attr.head-c::before {
        background-image: url(../../../assets/imgs/pc/children/img_touw@2x.png);
        width: 18px;
        height: 16px;
    }

    .dialog.ct .base-info .attrs > .attr.weight::before {
        background-image: url(../../../assets/imgs/pc/children/img_tiz@2x.png);
    }

    .dialog.ct .base-info .attrs > .attr.chest-c::before {
        background-image: url(../../../assets/imgs/pc/children/img_xiongw@2x.png);
        width: 18px;
        height: 13px;
    }

    .dialog.ct .base-info .attrs > .attr > .attr-v {
        margin-left: 8px;
    }

    .dialog.ct .hint-result-list {
        height: 18px;
        line-height: 18px;
        font-size: 18px;
        font-weight: bold;
        color: #1B2122;
        position: relative;
        padding-left: 34px;
        margin-left: 36px;
        margin-top: 10px;
    }

    .dialog.ct .hint-result-list::before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 18px;
        height: 18px;
        background-image: url(../../../assets/imgs/pc/children/img_rili@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .dialog.ct .result-list {
        display: flex;
        flex-direction: row;
        margin-left: 12px;
        margin-top: 14px;
        overflow-x: scroll;
    }

    .dialog.ct .result-list .result-items-l {
        display: inline-block;
        width: 248px;
        height: 240px;
        margin-right: 1px;
    }

    .dialog.ct .result-list .result-items-l .result-item-big {
        display: inline-block;
        height: 57px;
        padding: 0px 12px;
        box-sizing: border-box;
        width: 170px;
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        margin-top: 4px;
        justify-content: space-between;

    }

    .dialog.ct .result-list .result-items-l .result-item-big > .hint {
        font-size: 16px;
        font-weight: bold;
        color: #231F20;
        display: inline-block;
    }

    .dialog.ct .result-list .result-items-l .result-item-big > .value {
        font-size: 16px;
        color: #fff;
        display: inline-block;
        margin-right: 10px;
        font-weight: bold;
    }

    .dialog.ct .result-list .result-items-l .result-item-big:nth-child(1) {
        margin-top: 0px;
    }

    .dialog.ct .result-list .result-items-r {
        flex: 1;
        height: 240px;
        display: flex;
        flex-direction: column;
    }

    .dialog.ct .result-list .result-items {
        display: flex;
        flex-direction: row;
        margin-top: 4px;
    }

    .dialog.ct .result-list .result-items:nth-child(1) {
        margin-top: 0px;
    }

    .dialog.ct .result-list .result-items .result-item {
        display: flex;
        flex-direction: column;
        height: 57px;
        width: 122px;
        align-items: center;
        justify-content: space-between;
        margin-right: 1px;
    }

    .dialog.ct .result-list .result-items .result-item .result-item-title {
        display: inline-block;
        width: 122px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        color: #636363;
        font-weight: 400;
        font-size: 14px;
    }

    .dialog.ct .result-list .result-items .result-item.level0 .result-item-title {
        background-color: #FFC2C2;
    }

    .dialog.ct .result-list .result-items .result-item.level1 .result-item-title {
        background-color: #FFE6BB;
    }

    .dialog.ct .result-list .result-items .result-item.level2 .result-item-title {
        background-color: #FEFFC7;
    }

    .dialog.ct .result-list .result-items .result-item.level3 .result-item-title {
        background-color: #E9FFCF;
    }

    .dialog.ct .result-list .result-items .result-item.level4 .result-item-title {
        background-color: #BCF9C2;
    }

    .dialog.ct .result-list .result-items-l .result-item-big.level0,
    .dialog.ct .result-list .result-items .result-item.level0.checked .result-item-title {
        background-color: #C23029;
    }

    .dialog.ct .result-list .result-items-l .result-item-big.level1,
    .dialog.ct .result-list .result-items .result-item.level1.checked .result-item-title {
        background-color: #C47144;
    }

    .dialog.ct .result-list .result-items-l .result-item-big.level2,
    .dialog.ct .result-list .result-items .result-item.level2.checked .result-item-title {
        background-color: #C7A12E;
    }

    .dialog.ct .result-list .result-items-l .result-item-big.level3,
    .dialog.ct .result-list .result-items .result-item.level3.checked .result-item-title {
        background-color: #8C9E29;
    }

    .dialog.ct .result-list .result-items-l .result-item-big.level4,
    .dialog.ct .result-list .result-items .result-item.level4.checked .result-item-title {
        background-color: #4DB34D;
    }

    .dialog.ct .result-list .result-items .result-item.checked .result-item-title {
        color: #231F20;
        font-size: 14px;
        font-weight: bold;
    }


    .dialog.ct .result-list .result-items .result-item .result-item-value {
        display: inline-block;
        height: 36px;
        width: 122px;
        background-color: #E3EFF2;
        text-align: center;
        line-height: 36px;
        margin-top: 1px;
        font-size: 16px;
        font-weight: bold;
        color: #231F20;
    }

    .dialog.ct .v-result-list {
        display: flex;
        flex-direction: row;
        margin: 37px 0px 0px 36px;
    }

    .dialog.ct .v-result-list > .hint {
        font-size: 18px;
        line-height: 18px;
        color: #EE1B23;
        font-weight: bold;
        margin-right: 18px;
    }

    .dialog.ct .v-result-list > .value {
        font-size: 18px;
        line-height: 18px;
        color: #231F20;
        font-weight: bold;
        margin-right: 18px;
    }
</style>