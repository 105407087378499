<template>
    <div id="children">
        <v-head title="兒童健康"/>
        <div class="children-header">
            <h1>關注兒童生長發育問題</h1>
            <p>生長發育是嬰幼兒及青少年期所特有的生理現象。但生長發育不是簡單的身體由小增大的過程，而是一個十分複雜的現象。 牽涉到個體細胞的增加、分化，器官結構及功能的完善。 </p>
        </div>
        <div class="children-form">
            <div class="form-t">
                <div class="gender-container">
                    <img v-if="gender == 1" src="../../../assets/imgs/mobile/children/img_boy@2x.png" class="icon-gender">
                    <img v-if="gender == 2" src="../../../assets/imgs/mobile/children/img_girl@2x.png" class="icon-gender">
                    <span :class="{'gender': true, 'checked': gender == 1 }" @click="changeGender(1)">男孩</span>
                    <span :class="{'gender': true, 'checked': gender == 2 }" @click="changeGender(2)">女孩</span>
                </div>
                <div class="birthday" :data-value="birthday" @click="pickerDateTime">{{birthday || '请选择出生日期'}}</div>
            </div>
            <div class="form-row">
                <div class="form-item">
                    <label class="form-item-label" for="form-input01">身高:</label>
                    <input type="number" id="form-input01" v-model="height">
                    <span class="hint">cm</span>
                </div>
                <div class="form-item">
                    <label class="form-item-label" for="form-input02">體重:</label>
                    <input type="number" id="form-input02" v-model="weight">
                    <span class="hint">kg</span>
                </div>
            </div>
            <div class="form-row">
                <div class="form-item">
                    <label class="form-item-label" for="form-input03">頭圍:</label>
                    <input type="number" id="form-input03" v-model="head">
                    <span class="hint">cm</span>
                </div>
                <div class="form-item">
                    <label class="form-item-label" for="form-input04">胸圍:</label>
                    <input type="number" id="form-input04" v-model="chest">
                    <span class="hint">cm</span>
                </div>
            </div>
            <div class="btn-submit" @click="test"><div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在提交...' : '生長發育水平測試'}}</div>
        </div>
        <div class="children-growth">
            <img src="../../../assets/imgs/mobile/children/img_tuone@2x.png" alt="小兒生長發育">
            <div class="children-growth-intros">
                <div class="children-growth-intro">
                    <h2>小兒生長發育總的特點</h2>
                    <p>出生後頭2年身高、體重增長較快；2歲至青春期以前有較為穩定的增加；青春期快速增長，以後漸漸停止。 </p>
                </div>
                <div class="children-growth-intro">
                    <h2>體格發育有頭尾規律</h2>
                    <p>嬰幼兒期頭部發育領先，隨著年齡的增長，頭增長不多而四肢、軀幹增長速度加快。 嬰兒期頭部高度占全身的1/4，成人頭高占身高的1/ </p>
                </div>
            </div>
        </div>
        <router-view></router-view>
        <van-popup
            v-model="show"
            position="bottom"
            :style="{ height: '30%' }"
        >
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                :visible-item-count="3"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @cancel="onDateTimePickCancel"
                @confirm="onDateTimePickConfirm"
            />
        </van-popup>

        <test-result v-if="visible" :callback="onClose" :params="params" :testResult="testResult" />
    </div>
</template>

<script>
	import vHead from '@/layouts/MobileHead.vue'
    import TestResult from '@/views/mobile/children/TestResult.vue'
	export default {
		components: {
            vHead,
            TestResult,
		},
		data(){
            return {
                tabIndex: 1,
                show: false,
                minDate: new Date(1900, 0, 1),
                maxDate: new Date(),
                currentDate: new Date(),

                gender: 1,
                birthday: '',
                height: null,
                weight: null,
                head: null,
                chest: null,
                style: '',
                visible: false,
                loading: false,
                params: {},
                testResult: {},
            }
        },
        methods: {
            changeGender: function(v){
                this.gender = v
            },
            pickerDateTime: function(){
                this.show = !this.show
            },
            onDateTimePickCancel: function(){
                this.show = false
            },
            onDateTimePickConfirm: function(d){
                this.show = false
                var month = '' + (d.getMonth() + 1)
                var day = '' + d.getDate()
                var year = d.getFullYear()
                if (month.length < 2)
                    month = '0' + month
                if (day.length < 2)
                    day = '0' + day;
                this.birthday = [year, month, day].join('-')
            },
            formatter: function(type, val) {
                if (type === 'year') {
                    return `${val}年`;
                } else if (type === 'month') {
                    return `${val}月`;
                }
                return `${val}日`;
            },
            changeTab: function(index) {
                this.tabIndex = index
            },
            test: function(){
                if(this.loading) return
                if(!this.gender) {
                    this._toast.warning(this, '請選擇性別')
                    return
                }
                if(!this.birthday) {
                    this._toast.warning(this, '請選擇出生日期')
                    return
                }
                this.loading = true
                var params = {
                    "birthday": this.birthday,
                    "chest": this.chest || 0,
                    "gender": this.gender,
                    "head": this.head || 0,
                    "height": this.height || 0,
                    "weight": this.weight || 0,
                }
                this.$store.dispatch('childTest', params)
                .then((rs)=>{
                    this.loading = false
                    this.params = params
                    this.testResult = rs
                    this.visible = true
                    this.style = document.body.getAttribute('style') || ''
                    document.body.setAttribute('style', this.style + 'overflow: hidden;')
                }).catch((error)=>{
                    this.loading = false
                    this._toast.warning(this, error || '操作失敗')
                })
            },
            onClose: function(){
                this.visible = false
                document.body.setAttribute('style', this.style)
            }
        }
    }
</script>

<style lang="scss" scoped>
	#children {
		width: 100%;
		padding-top: 83px;
	}
	
    .children-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 254px;
        margin: 11px 24px 16px 24px;
        background-image: url(../../../assets/imgs/mobile/children/img_banerert@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .children-header > h1 {
        font-size: 36px;
        line-height: 34px;
        color: #fff;
        font-weight: bold;
        text-align: center;
        margin-top: 37px;
    }

    .children-header > p {
        font-size: 24px;
        line-height: 30px;
        color: #fff;
        font-weight: 400;
        box-sizing: border-box;
        padding: 0px 78px 0px 65px;
        text-align: center;
        margin-top: 22px;
    }

    /* 测试数据提交窗口 start */
    .children-form {
        display: flex;
        flex-direction: column;
        height: 520px;
        background-color: #fff;
        margin: 0px 24px;
    }

    .children-form > .form-item {
        display: flex;
        flex-direction: row;
    }

    .children-form > .form-t {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        margin: 46px 0px 59px 0px;
        justify-content: space-around;
    }

    .children-form > .form-t .gender {
        display: inline-block;
        width: 98px;
        height: 49px;
        line-height: 49px;
        text-align: center;
        color: #969696;
        font-weight: 400;
        font-size: 24px;
        background-color: #fff;
        border: 1px solid #36CCAD;
        box-sizing: border-box;
        cursor: pointer;
    }

    .children-form > .form-t .gender.checked {
        background-color: #36CCAD;
        color: #fff;
    }

    .children-form > .form-t .icon-gender {
        display: inline-block;
        width: 49px;
        height: 49px;
        margin-right: 16px;
    }

    .children-form > .form-t > .gender-container {
        display: flex;
        flex-direction: row;
    }
    
    .children-form > .form-t .birthday {
        height: 48px;
        line-height: 48px;
        width: 306ox;
        position: relative;
        display: flex;
        font-size: 24px;
        font-weight: 400;
        color: #231F20;
        flex-direction: row;
        box-sizing: border-box;
        padding-right: 55px;
        border: 1px solid #36CCAD;
        padding-left: 16px;
    }
    
    .children-form > .form-t .birthday[data-value=''] {
        color: #969696;
    }

    .children-form > .form-t .birthday::before {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url(../../../assets/imgs/mobile/children/img_ril@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 25px;
        height: 24px;
        z-index: 1;
    }

    .children-form > .form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .children-form > .form-row:nth-child(3) {
        margin-top: 48px;
    }

    .children-form > .form-row > .form-item {
        display: flex;
        flex-direction: row;
        height: 48px;
    }

    .children-form > .form-row > .form-item > label {
        height: 48px;
        line-height: 48px;
        font-weight: 400;
        font-size: 24px;
        color: #231F20;
        position: relative;
        margin-right: 13px;
        padding-left: 25px;
        display: inline-block;
        margin-left: 11px;
    }

    .children-form > .form-row > .form-item > label::before {
        content: '';
        position: absolute;
        left: 0px;
        width: 17px;
        height: 15px;
        top: 17px;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/mobile/children/img_sheng@2x.png);
        background-size: 100% 100%;
    }

    .children-form > .form-row:nth-child(2) > .form-item:nth-child(2) > label::before {
        background-image: url(../../../assets/imgs/mobile/children/img_tiz@2x.png);
    }

    .children-form > .form-row:nth-child(3) > .form-item:nth-child(1) > label::before {
        background-image: url(../../../assets/imgs/mobile/children/img_touw@2x.png);
    }

    .children-form > .form-row:nth-child(3) > .form-item:nth-child(2) > label::before {
        background-image: url(../../../assets/imgs/mobile/children/img_xiongw@2x.png);
    }

    .children-form > .form-row > .form-item > input {
        background-color: transparent;
        display: inline-block;
        width: 136px;
        height: 48px;
        padding-left: 6px;
        padding-right: 6px;
        box-sizing: border-box;
        border: 1px solid #2DAB90;
        color: #231F20;
        font-size: 24px;
        font-weight: bold;
    }

    .children-form > .form-row > .form-item > .hint {
        color: #231F20;
        line-height: 48px;
        font-weight: 400;
        font-size: 24px;
        margin-left: 10px;
        display: inline-block;
        width: 33px;
    }

    .children-form > .btn-submit {
        width: 448px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        color: #fff;
        border-radius: 8px;
        background-color: #36CCAD;
        font-size: 34px;
        font-weight: bold;
        cursor: pointer;
        margin: 80px auto 63px auto;
    }

    .children-form > .btn-submit:active {
        opacity: 0.7;
    }
    /* 测试数据提交窗口 end */


    .children-growth {
        display: flex;
        flex-direction: column;
        margin: 16px 24px;
        background-color: #fff;
    }

    .children-growth > img {
        width: 568px;
        height: 364px;
        margin-left: 32px;
        margin-top: 40px;
        margin-bottom: 47px;
    }

    .children-growth .children-growth-intro {
        display: flex;
        flex-direction: column;
    }

    .children-growth .children-growth-intro > h2 {
        font-size: 34px;
        line-height: 34px;
        color: #231F20;
        font-weight: bold;
        margin-left: 32px;
        
    }

    .children-growth .children-growth-intro > p {
        font-size: 28px;
        line-height: 37px;
        color: #636363;
        font-weight: 400;
        margin: 31px 32px 45px 33px;
    }

    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */ 
</style>
